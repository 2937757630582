<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2023. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

  <template>
  <div v-if="canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')" ref="nodeInformationDockerSystem">
    <v-col cols="12" md="12" s="6" xs="6" class="ml-1">
      <v-row>
        <span class="node-information-title">
          {{ $t('nodes.information.docker') }}
        </span>
      </v-row>
      <v-row v-resize="onResize" class="rowHeight mt-5">
        <v-col cols="10" class="pa-0">
          <v-btn text color="primary" class="pa-0 ma-0" :href="dockerWorkloadLogging" target="_blank">
            {{ $t('nodes.information.monitoringAndLoggingSettingsDialog.dockerLogging') }}
          </v-btn>
          <v-row>
            <span class="text--secondary node-information-text-light mt-3 ml-3">
              {{ $t('nodes.information.dockerLogDescription') }}
            </span>
          </v-row>
        </v-col>
        <v-col
          v-if="
            canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') && canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
          "
          cols="1"
          class="align-center"
        >
          <v-checkbox
            v-if="
              selectedNode.isOnline() &&
              monitoringAndLoggingStatus &&
              monitoringAndLoggingStatus.loggingMonitoring &&
              !filebeatError
            "
            id="iiotNodeInformationDockerSystemCheckboxDockerLogging"
            v-model="monitoringAndLoggingStatus.loggingMonitoring.dockerLogging"
            data-cy="iiotNodeInformationDockerSystemCheckboxDockerLogging"
            @click="changed"
          >
          </v-checkbox>
        </v-col>
        <v-col
          v-if="
            !canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') && canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
          "
          cols="1"
          class="align-center"
        >
          <v-checkbox
            v-if="selectedNode.isOnline() && monitoringAndLoggingStatus && !filebeatError"
            id="iiotNodeInformationDockerSystemCheckboxDockerLogging"
            v-model="monitoringAndLoggingStatus.dockerLogging"
            data-cy="iiotNodeInformationDockerSystemCheckboxDockerLogging"
            @click="changed"
          >
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row class="rowHeight" :class="{ 'mt-15': isMarginVisible }">
        <v-col class="pa-0" cols="10">
          <v-btn text color="primary" :href="dockerMonitoringUrl" target="_blank">
            {{ $t('nodes.information.monitoringAndLoggingSettingsDialog.dockerMonitoring') }}
          </v-btn>
          <v-row>
            <span class="text--secondary node-information-text-light mt-3 ml-3">
              {{ $t('nodes.information.dockerMetricsDescription') }}
            </span>
          </v-row>
        </v-col>

        <v-col
          v-if="
            canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') && canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
          "
          cols="1"
          class="align-center"
        >
          <v-checkbox
            v-if="
              selectedNode.isOnline() &&
              monitoringAndLoggingStatus &&
              monitoringAndLoggingStatus.loggingMonitoring &&
              !filebeatError
            "
            id="iiotNodeInformationDockerSystemCheckboxDockerMonitoring"
            v-model="monitoringAndLoggingStatus.loggingMonitoring.dockerMonitoring"
            data-cy="iiotNodeInformationDockerSystemCheckboxDockerMonitoring"
            @click="changed"
          >
          </v-checkbox>
        </v-col>
        <v-col
          v-if="
            !canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') && canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
          "
          cols="1"
          class="align-center"
        >
          <v-checkbox
            v-if="selectedNode.isOnline() && monitoringAndLoggingStatus && !filebeatError"
            id="iiotNodeInformationDockerSystemCheckboxDockerMonitoring"
            v-model="monitoringAndLoggingStatus.dockerMonitoring"
            data-cy="iiotNodeInformationDockerSystemCheckboxDockerMonitoring"
            @click="changed"
          >
          </v-checkbox>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
export default {
  props: {
    selectedNode: {
      type: Object,
      default: () => {},
    },
    filebeatError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChangedViewSystem: false,
      isMarginVisible: false,
    };
  },
  computed: {
    dockerMonitoringUrl() {
      return `${
        'opensearch/app/dashboards#/view/' +
        '61459e10-886c-11eb-bf13-27296ca4b8f1?' +
        "_a=(description:'Overview%20of%20docker%20workload%20utilization.',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'metricbeat-*',key:tags,negate:!f,params:(query:'"
      }${this.selectedNode.serialNumber}'),type:phrase),query:(match_phrase:(tags:'${
        this.selectedNode.serialNumber
      }')))),fullScreenMode:!f,options:(darkTheme:!f),panels:!((embeddableConfig:(),gridData:(h:16,i:'20e8205a-271f-40f6-8f17-af96f18e3547',w:24,x:0,y:0),id:'3f5c4830-886c-11eb-bf13-27296ca4b8f1',panelIndex:'20e8205a-271f-40f6-8f17-af96f18e3547',type:visualization,version:'7.7.0'),(embeddableConfig:(title:'CPU%20Usage'),gridData:(h:16,i:'1a61fe5b-1fa8-48b8-b06e-c23e8d49fcd1',w:24,x:24,y:0),id:'7e90b940-88a4-11eb-bf13-27296ca4b8f1',panelIndex:'1a61fe5b-1fa8-48b8-b06e-c23e8d49fcd1',title:'CPU%20Usage',type:visualization,version:'7.7.0'),(embeddableConfig:(title:'Containers%20Network%20IO'),gridData:(h:16,i:'1ee48d08-77dc-47ba-9a5f-a32b32aefd34',w:24,x:24,y:16),id:'60f27000-88a3-11eb-bf13-27296ca4b8f1',panelIndex:'1ee48d08-77dc-47ba-9a5f-a32b32aefd34',title:'Containers%20Network%20IO',type:visualization,version:'7.7.0'),(embeddableConfig:(title:'Memory%20Usage'),gridData:(h:16,i:ca5e67c1-16ed-42d9-b919-cfba21c689ed,w:24,x:0,y:16),id:ce1133a0-88a4-11eb-bf13-27296ca4b8f1,panelIndex:ca5e67c1-16ed-42d9-b919-cfba21c689ed,title:'Memory%20Usage',type:visualization,version:'7.7.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Docker%20Workload%20Utilization%20Dashboard',viewMode:view)&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))`;
    },
    dockerWorkloadLogging() {
      return `${
        'opensearch/app/dashboards#/view/' +
        'c9bb74a0-a88e-11eb-84ff-3fe7e5e5548d?' +
        "_a=(description:'Displays%20docker%20workload%20logs.',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'243502e0-6185-11ea-b149-f7a361aca0a3',key:tags,negate:!f,params:(query:'"
      }${this.selectedNode.serialNumber}'),type:phrase),query:(match_phrase:(tags:'${
        this.selectedNode.serialNumber
      }'))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'243502e0-6185-11ea-b149-f7a361aca0a3',key:tags,negate:!f,params:(query:docker),type:phrase),query:(match_phrase:(tags:docker))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'243502e0-6185-11ea-b149-f7a361aca0a3',key:container.name,negate:!t,params:!(nerve-ds-node_timescaledb_1,nerve-ds-node_supervisor-be_1,nerve-ds-node_grafana_1,nerve-ds-node_gateway_1),type:phrases,value:'nerve-ds-node_timescaledb_1,%20nerve-ds-node_supervisor-be_1,%20nerve-ds-node_grafana_1,%20nerve-ds-node_gateway_1'),query:(bool:(minimum_should_match:1,should:!((match_phrase:(container.name:nerve-ds-node_timescaledb_1)),(match_phrase:(container.name:nerve-ds-node_supervisor-be_1)),(match_phrase:(container.name:nerve-ds-node_grafana_1)),(match_phrase:(container.name:nerve-ds-node_gateway_1))))))),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:28,i:'7ae1da44-cf17-4b82-b43e-3846511d5c70',w:48,x:0,y:0),id:'028a2650-a88f-11eb-84ff-3fe7e5e5548d',panelIndex:'7ae1da44-cf17-4b82-b43e-3846511d5c70',type:search,version:'7.7.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Docker%20Workloads%20Logging%20Dashboard',viewMode:view)&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-5h,to:now))`;
    },
    monitoringAndLoggingStatus() {
      return this.$store.getters['nodes/getMonitoringAndLoggingSettings'];
    },
    initialValues() {
      return this.$store.getters['nodes/getMonitoringAndLoggingSettingsInitialValues'];
    },
  },
  methods: {
    mounted() {
      this.$store.dispatch('node-tree/restart_mqtt_events', this.selectedNode);
    },
    beforeDestroy() {
      this.$store.dispatch('node-tree/clear_exchange_interval');
      this.$mqtt.unsubscribeFrom('node', this.selectedNode);
    },
    changed() {
      if (
        !(
          this.canAccess('UI_NODE_LOG_LEVEL:MANAGE_LOG_LEVELS') &&
          this.canAccess('UI_NODE:LOGGING_AND_MONITORING_SETTINGS')
        )
      ) {
        this.isChanged =
          this.monitoringAndLoggingStatus.dockerLogging !== this.initialValues.dockerLogging ||
          this.monitoringAndLoggingStatus.dockerMonitoring !== this.initialValues.dockerMonitoring;
      } else {
        this.isChanged =
          this.monitoringAndLoggingStatus.loggingMonitoring.dockerLogging !==
            this.initialValues.loggingMonitoring.dockerLogging ||
          this.monitoringAndLoggingStatus.loggingMonitoring.dockerMonitoring !==
            this.initialValues.loggingMonitoring.dockerMonitoring;
      }
      if (this.isChanged) {
        this.$store.dispatch('nodes/set_docker_system_save_enabled', {
          isChangedDockerSystem: this.isChanged,
        });
      }
      if (!this.isChanged) {
        this.$store.dispatch('nodes/disable_docker_system_button', {
          isChangedDockerSystem: this.isChanged,
        });
      }
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 820 && window.innerWidth > 738;
    },
  },
};
</script>

  <style lang="scss">
.node-information-text-light {
  font-size: 13px;
}
.node-information-text {
  font-size: 14px;
}
.node-information-title {
  font-size: 18px;
  font-weight: 87%;
}
.rowHeight {
  height: 85px;
}
</style>
