<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <the-nerve-table
    :columns="columns"
    :empty-state="$t('nodes.list.empty')"
    :is-action-menu-enabled="false"
    :is-row-clickable="canAccess('UI_NODE:VIEW')"
    :have-access="canAccess('UI_NODE:DELETE')"
    :params="params"
    store-module="nodes"
    @row-clicked="(params) => editNode(params.item)"
    @params-changed="(params) => changedParams(params)"
  >
    <template #additional-actions>
      <div>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-if="canAccess('UI_NODE:CREATE')"
              id="iiotNodesListNewNodeButton"
              fab
              small
              dark
              class="primary-tttech-background-btn mr-4 ml-2"
              v-on="on"
              @click="addNewNode()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('nodes.list.addNode') }}</span>
        </v-tooltip>
      </div>
    </template>
  </the-nerve-table>
</template>

<script>
import { TheNerveTable } from 'nerve-ui-components';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    page: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    search: {
      type: String,
      default: '',
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      params: {},
    };
  },
  computed: {
    columns() {
      if (this.canAccess('UI_NODE:DELETE')) {
        return [
          {
            text: this.$t('nodes.list.name'),
            value: 'name',
            component: {
              sfc: () => import('@/components/nodes/node-list/NameColumn.vue'),
            },
          },
          {
            text: this.$t('nodes.list.serialNumber'),
            value: 'serialNumber',
          },
          {
            text: this.$t('nodes.list.version'),
            value: 'currentFWVersion',
          },
          {
            text: this.$t('nodes.information.created'),
            value: 'created',
            width: '25%',
          },
          {
            text: this.$t('defaultTable.action'),
            value: '',
            width: '0%',
            sortable: false,
            component: {
              sfc: () => import('@/components/nodes/node-list/NodeListTableAction.vue'),
              props: {
                iconsAction: ['Delete'],
                fetchAction: () => this.filterBy,
              },
            },
          },
        ];
      }
      return [
        {
          text: this.$t('nodes.list.name'),
          value: 'name',
          component: {
            sfc: () => import('@/components/nodes/node-list/NameColumn.vue'),
          },
        },
        {
          text: this.$t('nodes.list.serialNumber'),
          value: 'serialNumber',
        },
        {
          text: this.$t('nodes.list.version'),
          value: 'currentFWVersion',
        },
        {
          text: this.$t('nodes.information.created'),
          value: 'created',
        },
      ];
    },
  },
  created() {
    this.params = {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      search: this.search,
      sortBy: this.sortBy || [],
      sortDesc: this.sortDesc || [],
    };
  },
  methods: {
    async filterBy() {
      try {
        await this.$store.dispatch('nodes/fetch', this.params);
      } catch (e) {
        this.$log.debug(e);
      }
    },
    addNewNode() {
      this.$router.push({ name: 'Add edit node', params: { id: 'new' } });
    },
    changedParams(params) {
      this.params = params;
      this.$router
        .push({
          name: 'Nodes',
          query: {
            tab: 1,
            page: params.page,
            itemsPerPage: params.itemsPerPage,
            search: params.search || undefined,
            sortBy: params.sortBy ? params.sortBy[0] : [],
            sortDesc: params.sortDesc ? params.sortDesc[0] : [],
          },
        })
        .catch(() => {});
    },
    editNode(item) {
      this.$router.push({ name: 'Add edit node', params: { id: item.id } });
    },
  },
};
</script>
