<!-- eslint-disable vue/require-valid-default-prop -->
<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

  <template>
  <v-form ref="nodeInformationLogLevel" eager>
    <v-col v-if="monitoringAndLoggingStatus.logLevels" cols="12" md="12" s="6" xs="6" class="pl-1 mt-4">
      <v-row>
        <span class="node-information-text ml-3 mb-4">
          {{ $t('nodes.information.logLevels') }}
        </span>
      </v-row>
      <v-row
        v-if="
          monitoringAndLoggingStatus &&
          monitoringAndLoggingStatus.logLevels &&
          monitoringAndLoggingStatus.logLevels.values &&
          monitoringAndLoggingStatus.logLevels.values.ovdm &&
          monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm &&
          monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level
        "
      >
        <v-col cols="6">
          <v-select
            id="iiotNodeInformationLogLevelOvdm"
            v-model="monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm.level"
            :label="$t('nodes.information.nodeManagement')"
            :items="logLevelList"
            autocomplete="autocomplete"
            attach
            class="selector"
            @change="changeMonitoringAndLoggingSetting()"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="
          logLevelListLib.length !== 0 &&
          monitoringAndLoggingStatus &&
          monitoringAndLoggingStatus.logLevels &&
          monitoringAndLoggingStatus.logLevels.values &&
          monitoringAndLoggingStatus.logLevels.values.libvirt &&
          monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl &&
          monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level
        "
      >
        <v-col cols="6">
          <v-select
            id="iiotNodeInformationLogLevelLibvirt"
            v-model="monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.level"
            :items="logLevelListLib"
            :label="$t('nodes.information.virtualization')"
            autocomplete="autocomplete"
            attach
            class="selector"
            @change="changeMonitoringAndLoggingSetting()"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="
          logLevelListCodesys.length !== 0 &&
          monitoringAndLoggingStatus &&
          monitoringAndLoggingStatus.logLevels &&
          monitoringAndLoggingStatus.logLevels.values &&
          monitoringAndLoggingStatus.logLevels.values.codesys &&
          monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl &&
          monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level
        "
      >
        <v-col cols="6">
          <v-select
            id="iiotNodeInformationLogLevelCodesys"
            v-model="monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.level"
            :items="logLevelListCodesys"
            :label="$t('nodes.information.codesys')"
            autocomplete="autocomplete"
            attach
            class="selector"
            @change="changeMonitoringAndLoggingSetting()"
          />
        </v-col>
      </v-row>
      <v-row>
        <span class="node-information-text ml-3">
          {{ $t('nodes.information.setTime') }}
        </span>
      </v-row>
      <v-row class="ml-1 mt-6">
        <v-text-field
          id="iiotNodeInformationLogLevelTimeHours"
          v-model="time.hours"
          label="hours"
          :rules="[rules.min_hours, rules.max_hours]"
          :min="minHours"
          :max="maxHours"
          class="default-input-appearance timeInput"
          type="number"
        />
        <span class="mt-7 ml-2 mr-2"> : </span>
        <v-text-field
          id="iiotNodeInformationLogLevelTimeMinutes"
          v-model="time.minutes"
          label="minutes"
          :rules="[rules.min_minutes, rules.max_minutes]"
          :min="minMinutes"
          :max="maxMinutes"
          class="default-input-appearance timeInput"
          type="number"
        />
      </v-row>
      <span class="text--secondary node-information-text-light">
        {{ $t('nodes.information.timeDescription') }}
      </span>
    </v-col>
  </v-form>
</template>

<script>
import { MINUTES, HOURS } from '@/constants';

export default {
  props: {
    serialNumber: {
      type: String,
      default: '',
    },
    nodeVersion: {
      type: String,
      default: '',
    },
    initialObject: {
      type: Object,
      default: () => ({}),
    },
    isNodeLogLevelDialog: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      default: () => ({
        hours: 1,
        minutes: 0,
      }),
    },
    initialTime: {
      type: Object,
      default: () => ({
        hours: 1,
        minutes: 0,
      }),
    },
  },
  data() {
    return {
      minMinutes: MINUTES.MIN_LENGTH,
      minHours: HOURS.MIN_LENGTH,
      maxMinutes: MINUTES.MAX_LENGTH,
      maxHours: HOURS.MAX_LENGTH,
      setToAllLogLevel: '',
      notCorrectResponse: true,
      nodeNewVersion: false,
      responseObject: {},
      version: '2.1.2',
      ovdmValues: {
        0: 'verbose',
        3: 'error',
        4: 'warn',
        6: 'info',
        7: 'debug',
        8: 'silly',
      },
      progressBar: false,
      newNodeSetToAllList: [],
      setToAllOvdmValues: {
        0: '0',
        1: '3',
        2: '3',
        3: '3',
        4: '4',
        5: '6',
        6: '6',
        7: '7',
        8: '8',
      },
      list: {},
      newList: [],
      isChanged: false,
    };
  },
  computed: {
    rules() {
      return {
        max_hours: (v) => v <= HOURS.MAX_LENGTH || this.$t('nodeLogLevel.maxHours'),
        min_minutes: (v) => v >= MINUTES.MIN_LENGTH || this.$t('nodeLogLevel.minMinutes'),
        min_hours: (v) => v >= HOURS.MIN_LENGTH || this.$t('nodeLogLevel.minHours'),
        max_minutes: (v) => v <= MINUTES.MAX_LENGTH || this.$t('nodeLogLevel.maxMinutes'),
      };
    },
    monitoringAndLoggingStatus() {
      return this.$store.getters['nodes/getMonitoringAndLoggingSettings'];
    },
    initialMonitoringAndLogging() {
      return this.$store.getters['nodes/getMonitoringAndLoggingSettingsInitialValues'];
    },
    logLevelList() {
      if (this.monitoringAndLoggingStatus?.logLevels?.values?.ovdm?.ovdm) {
        const { options } = this.monitoringAndLoggingStatus.logLevels.values.ovdm.ovdm;
        return options.map((option) => option.value);
      }
      return [];
    },
    logLevelListLib() {
      if (this.monitoringAndLoggingStatus?.logLevels?.values?.libvirt?.libvirt_ctl) {
        const { options } = this.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl;
        return options.map((option) => option.name.toLowerCase());
      }
      return [];
    },
    logLevelListCodesys() {
      if (this.monitoringAndLoggingStatus?.logLevels?.values?.codesys?.codesys_ctl) {
        const { options } = this.monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl;
        return options.map((option) => option.name.toLowerCase());
      }
      return [];
    },
    getTimeout() {
      return this.$store.getters['nodes/getTimeout'];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'time.hours': function (newValue) {
      if (parseInt(newValue, 10) !== this.getTimeout.hours && this.$refs.nodeInformationLogLevel.validate()) {
        this.$store.dispatch('nodes/set_log_system_save_enabled', { isChangedLogLevel: true });
        const newTime = {
          hours: newValue,
          minutes: this.getTimeout.minutes,
        };
        this.$store.dispatch('nodes/set_timeout', newTime);
      }
      if (!this.$refs.nodeInformationLogLevel.validate() || parseInt(newValue, 10) === this.getTimeout.hours) {
        this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: false });
      }
    },
    // eslint-disable-next-line func-names
    'time.minutes': function (newValue) {
      if (parseInt(newValue, 10) !== this.getTimeout.minutes && this.$refs.nodeInformationLogLevel.validate()) {
        this.$store.dispatch('nodes/set_log_system_save_enabled', { isChangedLogLevel: true });
        const newTime = {
          hours: this.getTimeout.hours,
          minutes: newValue,
        };
        this.$store.dispatch('nodes/set_timeout', newTime);
      }
      if (!this.$refs.nodeInformationLogLevel.validate() || parseInt(newValue, 10) === this.getTimeout.minutes) {
        this.$store.dispatch('nodes/disable_log_system_button', { isChangedLogLevel: false });
      }
    },
  },
  created() {
    if (this.nodeVersion !== '') {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.nodeVersion) {
        this.nodeNewVersion = this.checkNodeVersion();
      }
      if (this.nodeNewVersion) {
        this.responseObject = this.monitoringAndLoggingStatus;
      }
    },
    changeMonitoringAndLoggingSetting() {
      if (this.initialMonitoringAndLogging?.logLevels?.values?.libvirt) {
        const lib_level = this.initialMonitoringAndLogging.logLevels.values.libvirt.libvirt_ctl.level;
        const lib_options = this.monitoringAndLoggingStatus.logLevels.values.libvirt.libvirt_ctl.options;
        const parse_level = parseInt(lib_level, 10);
        const found_lib = lib_options.find((option) => option.value === parse_level);
        if (found_lib) {
          this.initialMonitoringAndLogging.logLevels.values.libvirt.libvirt_ctl.level = found_lib.name.toLowerCase();
        }
        if (this.initialMonitoringAndLogging?.logLevels?.values?.codesys) {
          const codesys_level = this.initialMonitoringAndLogging.logLevels.values.codesys.codesys_ctl.level;
          const codesys_options = this.monitoringAndLoggingStatus.logLevels.values.codesys.codesys_ctl.options;
          const parse_level1 = parseInt(codesys_level, 10);
          const found_codesys = codesys_options.find((option) => option.value === parse_level1);
          if (found_codesys) {
            this.initialMonitoringAndLogging.logLevels.values.codesys.codesys_ctl.level =
              found_codesys.name.toLowerCase();
          }
        }
      }
      this.isChanged =
        this.initialMonitoringAndLogging?.logLevels?.values?.ovdm?.ovdm?.level !==
          this.monitoringAndLoggingStatus?.logLevels?.values?.ovdm?.ovdm?.level ||
        this.initialMonitoringAndLogging?.logLevels?.values?.libvirt?.libvirt_ctl?.level !==
          this.monitoringAndLoggingStatus?.logLevels?.values?.libvirt?.libvirt_ctl?.level ||
        this.initialMonitoringAndLogging?.logLevels?.values?.codesys?.codesys_ctl?.level !==
          this.monitoringAndLoggingStatus?.logLevels?.values?.codesys?.codesys_ctl?.level;

      if (this.isChanged) {
        this.$store.dispatch('nodes/set_log_system_save_enabled', {
          isChangedLogLevel: this.isChanged,
        });
      } else {
        this.$store.dispatch('nodes/disable_log_system_button', {
          isChangedLogLevel: this.isChanged,
        });
      }
    },
    checkNodeVersion() {
      const nodeV = this.nodeVersion.split('.');
      const v = this.version.split('.');
      if (parseInt(nodeV[0], 10) > parseInt(v[0], 10)) {
        return true;
      }
      if (parseInt(nodeV[0], 10) === parseInt(v[0], 10) && parseInt(nodeV[1], 10) > parseInt(v[1], 10)) {
        return true;
      }
      if (
        parseInt(nodeV[0], 10) === parseInt(v[0], 10) &&
        parseInt(nodeV[1], 10) === parseInt(v[1], 10) &&
        parseInt(nodeV[2], 10) > parseInt(v[2], 10)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
.node-information-text {
  font-size: 16px;
  font-weight: 67% !important;
}
.node-information-text-light {
  font-size: 13px;
}
.selector {
  max-width: 200px !important;
}
.selector .v-select__selection.v-select__selection--comma {
  margin-bottom: 0px !important;
  padding-bottom: 7px !important;
}
.timeInput {
  max-width: 56px !important;
}
.timeInput .v-label.v-label--active.theme--light {
  max-height: 20px !important;
}
</style>
