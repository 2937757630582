<template>
  <v-row no-gutters>
    <span class="font-size-14 text--disabled margin-top-2">
      {{ $t('nodes.dna.status') }}
    </span>
    <span
      id="iiotStatusColor"
      :class="[
        { 'status-color-green': status === DNA_STATUSES.applied },
        { 'status-color-blue fading': status === DNA_STATUSES.canceling },
        { 'status-color-orange': status === DNA_STATUSES.modified },
        { 'status-color-blue fading': status === DNA_STATUSES.reconfiguring },
        { 'status-color-red': status === DNA_STATUSES.undefined },
      ]"
      class="font-size-16 ml-2"
    >
      {{ $t(`nodes.dna.${status}`) }}
    </span>
    <span>
      <div v-if="status === DNA_STATUSES.undefined && message" class="tooltip" @mouseleave="openDialog = false">
        <v-img
          :id="`iiotDNAUndefinedStateIcon`"
          :src="`/img/warning.svg`"
          max-height="15px"
          max-width="15px"
          class="ml-2 mt-1 pointer"
          @mouseover="openDialog = true"
        />
        <div v-if="openDialog" id="iiotDNAUndefinedErrorMessageDialog" class="tooltiptext pr-2 pl-2 cursor-icon">
          <p class="feedback-title-font mb-3 mt-1 ml-n1">
            {{ $t('nodes.dna.errorFeedbackTitle') }}
          </p>
          <p v-if="!!message" class="mb-1">
            <span class="opacity-5 feedback-text-color"> {{ $t('nodes.dna.errorMessage') }} </span
            ><span id="iiotDNAUndefinedErrorMessage" class="feedback-text-color">{{ message }}</span>
          </p>
        </div>
      </div>
    </span>
  </v-row>
</template>

<script setup>
import { ref } from 'vue';
import { DNA_STATUSES } from '@/constants';

/*
  props
*/

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  status: {
    required: true,
  },
  message: {
    required: false,
  },
});

const openDialog = ref(false);
</script>

<style>
.status-color-red {
  color: #eb0c0c;
}
.status-color-green {
  color: #44b384;
}
.status-color-blue {
  color: var(--v-primary-base);
}
.status-color-orange {
  color: #f7a605;
}

.margin-top-2 {
  margin-top: 2px;
}

.feedback-title-font {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}
.opacity-5 {
  opacity: 0.5;
}

.tooltip {
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptext {
  max-width: 950px;
  padding: 10px 10px 10px 10px;
  width: fit-content;
  white-space: pre-wrap;
  background-color: rgb(240, 243, 245);
  text-align: left;
  opacity: 1;
  margin-top: 10px;
}

.tooltip .tooltiptext::after {
  position: fixed;
}
</style>
